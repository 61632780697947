import { useState, useEffect } from 'react';
import { formatMessageTime } from '../utils/timeUtils';

export const useMessagePlayback = (messages = []) => {
    const [displayedMessages, setDisplayedMessages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [typingUser, setTypingUser] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const totalDuration = 260;

    const startPlayback = () => {
        setIsPlaying(true);
    };

    useEffect(() => {
        if (!messages.length || !isPlaying) return;

        if (currentIndex >= messages.length) {
            if (displayedMessages.length === messages.length) {
                setIsComplete(true);
                setElapsedTime(totalDuration);
            }
            return;
        }

        const message = messages[currentIndex];
        setTypingUser(message.user);

        const progressPerMessage = totalDuration / messages.length;
        setElapsedTime(currentIndex * progressPerMessage);

        const baseDelay = 400;
        const wordCount = message.content.split(' ').length;
        const readingTime = wordCount * 400;
        const delay = Math.max(baseDelay, readingTime);

        const messageTimer = setTimeout(() => {
            setTypingUser(null);
            setDisplayedMessages(prev => [...prev, message]);
            setCurrentIndex(prev => prev + 1);
        }, delay);

        return () => clearTimeout(messageTimer);
    }, [currentIndex, messages, displayedMessages.length, isPlaying, totalDuration]);

    return {
        messages: displayedMessages,
        typingUser,
        isComplete,
        previewMessage: messages[currentIndex],
        elapsedTime,
        totalDuration,
        startPlayback
    };
};