import React, { useState, useEffect } from 'react';

const MIN_SCREEN_WIDTH = 768;

const ScreenSizeCheck = ({ children }) => {
    const [isScreenTooSmall, setIsScreenTooSmall] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsScreenTooSmall(window.innerWidth < MIN_SCREEN_WIDTH);
        };

        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    if (isScreenTooSmall) {
        return (
            <div className="min-h-screen bg-yellow-50 p-4 flex items-center justify-center">
                <div className="max-w-md bg-white rounded-xl shadow-lg p-8 text-center">
                    <div className="text-4xl mb-4">📱 ⚠️ 💻</div>
                    <h1 className="text-2xl font-bold text-gray-900 mb-4">
                        Desktop Required
                    </h1>
                    <p className="text-gray-600 mb-4">
                        This evaluation study requires a larger screen for optimal experience.
                    </p>
                    <div className="bg-yellow-50 p-4 rounded-lg">
                        <p className="font-medium text-yellow-800 mb-2">Please use:</p>
                        <ul className="text-yellow-700 space-y-2">
                            <li>• A desktop computer</li>
                            <li>• A laptop</li>
                            <li>• A screen width of at least 1024px</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return children;
};

export default ScreenSizeCheck; 