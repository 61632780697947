import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoInformationCircleOutline, IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import { EVALUATION_CRITERIA } from './constants';
import { useTeamFeedbackData } from '../hooks/useTeamFeedbackData';

const LIKERT_SCALE = [
    { value: 1, label: "Strongly Disagree" },
    { value: 2, label: "Disagree" },
    { value: 3, label: "Neutral" },
    { value: 4, label: "Agree" },
    { value: 5, label: "Strongly Agree" }
];

const TeamFeedbackEvaluation = ({ onComplete }) => {
    const { feedbacks, loading, error } = useTeamFeedbackData();
    const [currentFeedbackIndex, setCurrentFeedbackIndex] = useState(0);
    const [feedbackOrder, setFeedbackOrder] = useState([]);
    const [allEvaluations, setAllEvaluations] = useState([]);
    const [ratings, setRatings] = useState({});
    const [generalComment, setGeneralComment] = useState('');
    const [showComments, setShowComments] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [showSubExplanation, setShowSubExplanation] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(45);
    const [canSubmit, setCanSubmit] = useState(false);
    const [hasExpandedFeedback, setHasExpandedFeedback] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        if (feedbacks && feedbacks.length > 0) {
            const indices = Array.from({ length: feedbacks.length }, (_, i) => i);
            const shuffled = indices.sort(() => Math.random() - 0.5);
            setFeedbackOrder(shuffled);
        }
    }, [feedbacks, setIsSticky]);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset > 200);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [setIsSticky]);

    useEffect(() => {
        let timer;
        if (timeRemaining > 0 && hasExpandedFeedback) {
            timer = setInterval(() => {
                setTimeRemaining(prev => prev - 1);
            }, 1000);
        } else if (timeRemaining === 0) {
            setCanSubmit(true);
        }
        return () => clearInterval(timer);
    }, [timeRemaining, hasExpandedFeedback, setCanSubmit]);

    const handleRatingChange = (evaluationId, value) => {
        if (!hasExpandedFeedback) {
            setShowWarning(true);
            setTimeout(() => setShowWarning(false), 3000);
            return;
        }
        setRatings(prev => ({
            ...prev,
            [evaluationId]: value
        }));
    };

    const allCriteriaRated = () => {
        return EVALUATION_CRITERIA.every(criteria => ratings[criteria.id]);
    };

    const handleSubmit = async () => {
        if (!allCriteriaRated()) {
            alert('Please rate all criteria before submitting.');
            return;
        }

        setIsTransitioning(true);
        try {
            const currentEvaluation = {
                promptId: feedbackOrder[currentFeedbackIndex] + 1,
                responses: EVALUATION_CRITERIA.map(criteria => ({
                    evaluationId: criteria.id,
                    rating: parseInt(ratings[criteria.id])
                })),
                comment: generalComment
            };

            const newAllEvaluations = [...allEvaluations, currentEvaluation];
            setAllEvaluations(newAllEvaluations);

            if (currentFeedbackIndex === feedbackOrder.length - 1) {
                const evaluationData = {
                    metadata: {
                        version: "1.0",
                        evaluationType: "team",
                        totalPrompts: 9,
                        submissionComplete: true,
                        timestamp: new Date().toISOString()
                    },
                    ratings: newAllEvaluations,
                    feedback: {
                        generalComment: generalComment || "",
                        hasComment: !!generalComment
                    }
                };

                await onComplete(evaluationData);
                setShowComments(true);
            } else {
                setTimeout(() => {
                    setRatings({});
                    setGeneralComment('');
                    setCurrentFeedbackIndex(prev => prev + 1);
                    setTimeRemaining(45);
                    setCanSubmit(false);
                    setHasExpandedFeedback(false);
                    setIsExpanded(false);
                    
                    setTimeout(() => {
                        setIsTransitioning(false);
                    }, 500);
                }, 500);
            }
        } catch (error) {
            console.error('Error submitting evaluation:', error);
        }
    };

    if (loading) return <div>Loading team feedback...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!feedbacks || !feedbackOrder.length) return <div>No feedback available</div>;

    const currentFeedback = feedbacks[feedbackOrder[currentFeedbackIndex]];

    if (showComments) {
        return (
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="min-h-screen bg-gray-50 p-4"
            >
                <div className="max-w-6xl mx-auto">
                    <div className="bg-white rounded-xl shadow-lg p-8">
                        <h2 className="text-2xl font-bold text-gray-900 mb-6">Final Comments</h2>
                        <div className="space-y-6">
                            <div className="bg-gray-50 p-4 rounded-lg">
                                <p className="font-medium mb-2">Do you have any additional thoughts about the team feedback?</p>
                                <textarea
                                    placeholder="Share your thoughts... (optional)"
                                    className="w-full p-4 border rounded-lg min-h-[200px]"
                                    value={generalComment}
                                    onChange={(e) => setGeneralComment(e.target.value)}
                                />
                            </div>
                            <button
                                onClick={() => onComplete({ ratings: allEvaluations, generalComment })}
                                className="w-full py-3 bg-green-600 text-white rounded-lg hover:bg-green-700"
                            >
                                Complete Evaluation
                            </button>
                        </div>
                    </div>
                </div>
            </motion.div>
        );
    }

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="min-h-screen bg-gray-50 p-4 relative"
        >
            {/* Loading Overlay */}
            <AnimatePresence>
                {isTransitioning && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-white bg-opacity-90 z-50 flex items-center justify-center"
                    >
                        <div className="text-center">
                            <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
                            <p className="text-lg text-gray-700">Loading next feedback...</p>
                            <p className="text-sm text-gray-500 mt-2">
                                Feedback {currentFeedbackIndex + 1} of {feedbackOrder.length}
                            </p>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <div className="max-w-6xl mx-auto">
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="p-6">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-gray-900">
                                Team Evaluate Feedback {currentFeedbackIndex + 1} of {feedbacks.length}
                            </h2>
                            <div className={`font-medium ${
                                timeRemaining === 0 
                                    ? 'text-green-600' 
                                    : timeRemaining <= 10 
                                        ? 'text-green-500' 
                                        : 'text-blue-600'
                            }`}>
                                Time Remaining: {timeRemaining}s
                            </div>
                        </div>
                        <div className="bg-white border rounded-lg shadow-sm mb-8">
                            <div className="p-4 border-b bg-gradient-to-r from-blue-50 to-blue-100">
                                <div className="flex justify-between items-center">
                                    <h3 className="font-medium text-blue-900">Team Feedback #{currentFeedback.prompt_number} to Evaluate:</h3>
                                    <button 
                                        onClick={() => {
                                            setIsExpanded(!isExpanded);
                                            if (!hasExpandedFeedback) {
                                                setHasExpandedFeedback(true);
                                                setTimeRemaining(45);
                                                setCanSubmit(false);
                                            }
                                        }}
                                        className="text-blue-600 hover:text-blue-700 text-sm flex items-center gap-1 transition-colors"
                                    >
                                        {isExpanded ? (
                                            <>
                                                <span>Show Less</span>
                                                <IoChevronUpOutline size={16} />
                                            </>
                                        ) : (
                                            <>
                                                <span>Show More</span>
                                                <IoChevronDownOutline size={16} />
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <motion.div 
                                animate={{ height: isExpanded ? 'auto' : '150px' }}
                                transition={{ duration: 0.3 }}
                                className="relative overflow-hidden"
                            >
                                <div className="p-6">
                                    <div className="prose max-w-none">
                                        <p className="text-gray-800 leading-relaxed whitespace-pre-wrap text-lg">
                                            {currentFeedback?.content}
                                        </p>
                                    </div>
                                </div>
                                {!isExpanded && (
                                    <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-white to-transparent" />
                                )}
                            </motion.div>
                        </div>
                        
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="px-4 py-3 text-left">Criteria</th>
                                    {LIKERT_SCALE.map(scale => (
                                        <th key={scale.value} className="px-4 py-3 text-center w-24">
                                            {scale.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {EVALUATION_CRITERIA.map(criteria => (
                                    <React.Fragment key={criteria.id}>
                                        <tr className="border-t">
                                            <td className="px-4 py-4">
                                                <div className="flex items-start gap-2">
                                                    <div>
                                                        <p className="font-medium">{criteria.text}</p>
                                                        <p className="text-sm text-gray-600 mt-1">
                                                            {criteria.explanation}
                                                        </p>
                                                        <button
                                                            onClick={() => setShowSubExplanation(
                                                                showSubExplanation === criteria.id ? null : criteria.id
                                                            )}
                                                            className="text-blue-600 hover:text-blue-700 mt-2 flex items-center gap-1"
                                                        >
                                                            <IoInformationCircleOutline size={18} />
                                                            <span className="text-sm">More info</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            {LIKERT_SCALE.map(scale => (
                                                <td key={scale.value} className="px-4 py-4 text-center">
                                                    <input
                                                        type="radio"
                                                        name={`criteria-${criteria.id}`}
                                                        value={scale.value}
                                                        checked={ratings[criteria.id] === scale.value}
                                                        onChange={() => handleRatingChange(criteria.id, scale.value)}
                                                        className="w-4 h-4 text-blue-600 cursor-pointer"
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                        {showSubExplanation === criteria.id && (
                                            <tr className="bg-gray-50">
                                                <td colSpan={6} className="px-4 py-3">
                                                    <p className="text-sm text-gray-600 italic">
                                                        {criteria.subExplanation}
                                                    </p>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>

                        <div className="mt-8">
                            <label className="block font-medium mb-2">
                                Additional Comments (Optional):
                            </label>
                            <textarea
                                value={generalComment}
                                onChange={(e) => setGeneralComment(e.target.value)}
                                className="w-full p-3 border rounded-lg h-32"
                                placeholder="Any additional thoughts about this feedback?"
                            />
                        </div>

                        <div className="mt-8 flex justify-between items-center">
                            <div className="text-sm text-gray-600">
                                Feedback {currentFeedbackIndex + 1} of {feedbacks.length}
                            </div>
                            <button
                                onClick={handleSubmit}
                                disabled={!allCriteriaRated() || !canSubmit}
                                className={`px-6 py-2 rounded-lg font-medium ${
                                    allCriteriaRated() && canSubmit
                                        ? 'bg-green-600 text-white hover:bg-green-700'
                                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                }`}
                            >
                                {!canSubmit 
                                    ? 'Please wait...' 
                                    : currentFeedbackIndex === feedbackOrder.length - 1 
                                        ? 'Complete' 
                                        : 'Next Feedback'
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {showWarning && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    className="fixed inset-0 flex items-center justify-center z-50"
                >
                    <div className="bg-white border border-yellow-200 shadow-xl rounded-lg p-6 max-w-md mx-auto">
                        <div className="flex items-center gap-3 text-yellow-800">
                            <IoInformationCircleOutline size={24} />
                            <span className="text-lg font-medium">Please read the feedback first</span>
                        </div>
                        <p className="mt-2 text-gray-600">
                            Expand the feedback section by clicking "Show More" before rating.
                        </p>
                    </div>
                    <div className="fixed inset-0 bg-black bg-opacity-20 -z-10" />
                </motion.div>
            )}
        </motion.div>
    );
};

export default TeamFeedbackEvaluation; 