import React from 'react';
import { motion } from 'framer-motion';

const EvaluationIntroPage = ({ type, onStart, isCompletionPage = false }) => {
    const content = {
        individual: {
            title: "Individual Feedback Evaluation",
            description: "You're about to evaluate feedback about your personal performance",
            details: [
                "This feedback is specifically about your contributions and interactions",
                "It focuses on your individual problem-solving and communication skills",
                "The feedback analyzes your personal approach to the survival task",
                "You'll evaluate how well this feedback could help you improve"
            ],
            examples: [
                "How you contributed ideas to the discussion",
                "Your communication style with team members",
                "Your approach to problem-solving",
                "Your role in the team's decision-making process"
            ]
        },
        team: {
            title: "Team Feedback Evaluation",
            description: "You're about to evaluate feedback about your team's collaboration",
            details: [
                "This feedback is about how your team worked together",
                "It focuses on group dynamics and collective decision-making",
                "The feedback analyzes team coordination and collaboration",
                "You'll evaluate how well this feedback could help improve team performance"
            ],
            examples: [
                "How well the team coordinated their efforts",
                "The group's approach to reaching consensus",
                "Team communication effectiveness",
                "Collective problem-solving strategies"
            ]
        }
    };

    const selected = content[type];

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="min-h-screen bg-gray-50 p-4"
        >
            <div className="max-w-4xl mx-auto">
                <div className="bg-white rounded-xl shadow-lg p-8">
                    <h1 className="text-2xl font-bold text-gray-900 mb-6">
                        {type === 'individual' ? 'Individual' : 'Team'} Feedback Evaluation
                    </h1>

                    <div className="bg-white rounded-xl shadow-lg p-8">
                        <h1 className="text-3xl font-bold text-gray-900 mb-6">
                            {selected.title}
                        </h1>

                        <div className="bg-blue-50 p-6 rounded-lg border border-blue-100 mb-6">
                            <p className="text-lg text-blue-800 mb-4">
                                {selected.description}
                            </p>
                            <h2 className="font-semibold text-blue-900 mb-3">What to expect:</h2>
                            <ul className="space-y-2 text-blue-700">
                                {selected.details.map((detail, index) => (
                                    <li key={index} className="flex items-center">
                                        <span className="mr-2">•</span>
                                        {detail}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {!isCompletionPage && (
                            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
                                <h2 className="text-lg font-semibold text-yellow-800 mb-2">⚠️ Important Notes:</h2>
                                <ul className="list-disc list-inside space-y-2 text-yellow-700">
                                    <li>Please DO NOT use browser back button during the evaluation</li>
                                    <li>DO NOT refresh the page - your progress will be lost</li>
                                    <li>Your responses will only be saved after completing all evaluation questions</li>
                                    <li>The evaluation must be completed in one session</li>
                                </ul>
                            </div>
                        )}

                        <div className="bg-green-50 p-6 rounded-lg border border-green-100 mb-8">
                            <h2 className="font-semibold text-green-900 mb-3">Examples of what this feedback covers:</h2>
                            <ul className="space-y-2 text-green-700">
                                {selected.examples.map((example, index) => (
                                    <li key={index} className="flex items-center">
                                        <span className="mr-2">•</span>
                                        {example}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <button
                            onClick={onStart}
                            className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg
                                     hover:bg-blue-700 transition-colors duration-200
                                     flex items-center justify-center gap-2"
                        >
                            Begin {type === 'individual' ? 'Individual' : 'Team'} Evaluation
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default EvaluationIntroPage; 