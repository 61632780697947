import React, { useState, useEffect } from 'react';
import ChatHeader from './components/ChatHeader';
import ChatTimeline from './components/ChatTimeline';
import useFileReader from './hooks/useFileReader';
import { useMessagePlayback } from './hooks/useMessagePlayback';
import ProgressBar from './components/ProgressBar';
import LoginPage from './components/LoginPage';
import ProjectInfoPage from './components/ProjectInfoPage';
import { calculateAbsoluteDifference, calculateScore } from './utils/scoring';
import PromptEvaluationPage from './components/PromptEvaluationPage';
import { checkUserSubmissions } from './services/parseService';
import ScreenSizeCheck from './components/ScreenSizeCheck';

const TASK_DESCRIPTION = `You are (Green) a member of a group that have just survived the crash of a small plane.
The crash occurred in Northern Canada during mid-January. The daily temperature is -25°C, and the night temperature is -40°C.
You are all dressed in city clothes appropriate for a business meeting.
Your task is to rank 15 items salvaged from the wreckage in order of their importance to your survival.`;

const UNRANKED_ITEMS = [
    "Air Map",
    "Chocolate Bar",
    "Cigarette Lighter without fluid",
    "Compass",
    "Compress Kit",
    "Flashlight",
    "Knife",
    "Newspaper",
    "Pistol",
    "Rope",
    "Shirt/Pants",
    "Ski Poles",
    "Shortening",
    "Steel Wool",
    "Whiskey"
];
// This is for old dataset with 12 minutes
// const FINAL_RANKINGS = [
//     "Knife",
//     "Compass",
//     "Cigarette lighter without fluid",
//     "steel wool",
//     "Air map",
//     "compress kit",
//     "Pistol",
//     "Rope",
//     "Shirt/Pants",
//     "Whiskey",
//     "Shortening",
//     "Flashlight",
//     "Ski Poles",
//     "Chocolate Bar",
//     "Newspaper"
// ];


const FINAL_RANKINGS = [
    "Compress Kit",
    "Air Map",
    "Whiskey",
    "Compass",
    "Pistol",
    "Shortening",
    "Cigarette Lighter",
    "Newspaper",
    "Ski Poles",
    "Shirt/Pants",
    "Chocolate Bar",
    "Flashlight",
    "Knife",
    "Steel Wool",
    "Rope"
];

const EXPERT_FINAL_RANKINGS = [
    "Knife",
    "Cigarette lighter without fluid",
    "Steel wool",
    "Compass",
    "Air map",
    "Rope",
    "Compress kit",
    "Flashlight",
    "Shirt/Pants",
    "Pistol",
    "Whiskey",
    "Shortening",
    "Chocolate Bar",
    "Newspaper",
    "Ski Poles"
];

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userSubmissions, setUserSubmissions] = useState(null);
    const [showProjectInfo, setShowProjectInfo] = useState(false);
    const [conversationStarted, setConversationStarted] = useState(false);
    const [showEvaluation, setShowEvaluation] = useState(false);
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState(null);
    // const { messages: rawMessages, loading, error } = useFileReader('/data/CSV_Group_1_Feb_8_429.csv'); We removed this since it takes a long time to finish (12 minutes)
    const { messages: rawMessages, loading, error } = useFileReader('/data/CSV_Group_20_Oct_22_135.csv'); // We replaced the dataset with a shorter one 141 utterances
    const { 
        messages, 
        typingUser, 
        isComplete,
        previewMessage,
        elapsedTime,
        totalDuration,
        startPlayback 
    } = useMessagePlayback(rawMessages);
    const [showingExpertReview, setShowingExpertReview] = useState(false);
    const [reviewTimeRemaining, setReviewTimeRemaining] = useState(30);

    useEffect(() => {
        if (isComplete && !showingExpertReview) {
            setShowingExpertReview(true);
            setReviewTimeRemaining(30);
            
            const timer = setInterval(() => {
                setReviewTimeRemaining((prevTime) => {
                    const newTime = prevTime - 1;
                    if (newTime <= 0) {
                        clearInterval(timer);
                        setShowEvaluation(true);
                        setShowingExpertReview(false);
                        return 0;
                    }
                    return newTime;
                });
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [isComplete]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            const message = "Warning: Your progress will be lost if you leave or refresh the page. Are you sure?";
            e.returnValue = message;
            return message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const handleLogin = async (user) => {
        try {
            setUserId(user.id);
            setUserName(user.displayName);
            
            // Check existing submissions
            const submissions = await checkUserSubmissions(user.id);
            setUserSubmissions(submissions);
            
            if (submissions.isComplete) {
                alert('You have already completed both evaluations. Thank you for your participation!');
                return;
            }
            
            setIsAuthenticated(true);
            setShowProjectInfo(true);
        } catch (error) {
            console.error('Error during login:', error);
            alert('There was an error checking your submissions. Please try again.');
        }
    };

    const score = calculateScore(calculateAbsoluteDifference(FINAL_RANKINGS, EXPERT_FINAL_RANKINGS));

    return (
        <ScreenSizeCheck>
            {!isAuthenticated ? (
                <LoginPage onLogin={handleLogin} />
            ) : userSubmissions?.isComplete ? (
                <div className="flex items-center justify-center h-screen">
                    <div className="text-center p-8 bg-white rounded-lg shadow-lg">
                        <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
                        <p className="text-gray-600">
                            You have already completed both evaluations.
                            We appreciate your participation!
                        </p>
                    </div>
                </div>
            ) : loading ? (
                <div className="flex items-center justify-center h-screen">Loading...</div>
            ) : error ? (
                <div className="flex items-center justify-center h-screen text-red-500">{error}</div>
            ) : showProjectInfo ? (
                <ProjectInfoPage onContinue={() => setShowProjectInfo(false)} />
            ) : !conversationStarted ? (
                <div className="min-h-screen flex items-center justify-center bg-gray-50">
                    <div className="max-w-2xl w-full p-8 bg-white rounded-xl shadow-lg text-center">
                        <h2 className="text-2xl font-bold text-gray-900 mb-4">Ready to Begin?</h2>
                        <p className="text-gray-600 mb-8">
                            Welcome {userName}, you'll be participating as Green in the survival discussion.
                            The conversation will last approximately 5 minutes.
                        </p>
                        <button
                            onClick={() => {
                                setConversationStarted(true);
                                startPlayback();
                            }}
                            className="px-8 py-3 bg-green-600 text-white rounded-lg
                                     hover:bg-green-700 transition-colors duration-200"
                        >
                            Start Discussion
                        </button>
                    </div>
                </div>
            ) : showEvaluation ? (
                <PromptEvaluationPage userName={userName} loginId={userId} score={score} />
            ) : (
                <div className="flex h-screen gap-4 p-4 bg-gray-100">
                    <div className="w-1/2 flex flex-col gap-4">
                        <div className="bg-white p-4 rounded-lg shadow-lg">
                            <div className="flex items-center gap-3 mb-3">
                                <div className="w-10 h-10 rounded-full bg-green-500 flex items-center justify-center text-white text-xl">
                                    🧑
                                </div>
                                <div>
                                    <h2 className="text-lg font-bold text-green-700">{userName} (Green)</h2>
                                    <p className="text-sm text-gray-500">As Green, participate in the survival discussion</p>
                                </div>
                            </div>
                            <p className="text-sm text-gray-700 bg-green-50 p-3 rounded-lg border border-green-100">
                                {TASK_DESCRIPTION}
                            </p>
                        </div>

                        <div className="flex-1 bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
                            <ChatHeader />
                            
                            <ProgressBar
                                elapsedTime={elapsedTime} 
                                totalDuration={totalDuration}
                            />
                            
                            <ChatTimeline messages={messages} typingUser={typingUser} />
                            
                            <div className="p-4 border-t bg-gray-50">
                                <div className="flex gap-2">
                                    <input
                                        type="text"
                                        value={previewMessage && previewMessage.user === 'green' ? previewMessage.content : ''}
                                        disabled
                                        placeholder="Green's next message..."
                                        className="flex-1 px-4 py-2 rounded-full border border-gray-200 bg-gray-100 text-gray-600"
                                    />
                                    <button
                                        disabled
                                        className="px-4 py-2 bg-green-500 text-white rounded-full opacity-50"
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-1/2 flex flex-col gap-4">
                        <div className="bg-white p-4 rounded-lg shadow-lg h-full flex flex-col">
                            <div className="flex gap-4 h-full">
                                {/* Unranked Items Column */}
                                <div className="flex-1 overflow-y-auto">
                                    <h3 className="text-md font-bold mb-3 text-gray-800 sticky top-0 bg-white">Available Items</h3>
                                    <div className="space-y-2">
                                        {UNRANKED_ITEMS.map((item, index) => (
                                            <div 
                                                key={index}
                                                className="p-2.5 text-sm bg-gray-50 rounded-lg border border-gray-200 hover:bg-gray-100 transition-colors"
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="flex-1 border-l border-gray-200 pl-4 flex flex-col">
                                    <h3 className="text-md font-bold mb-3 text-gray-800 sticky top-0 bg-white">
                                        {isComplete ? "Final Rankings" : "Rankings will appear here"}
                                    </h3>
                                    {isComplete && (
                                        <div className="mb-4 p-4 bg-blue-50 rounded-lg border border-blue-100 sticky top-12">
                                            <h4 className="font-semibold text-blue-900 mb-2">Performance Score</h4>
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap-2">
                                                    <div className="text-2xl font-bold text-blue-600">
                                                        {calculateScore(calculateAbsoluteDifference(FINAL_RANKINGS, EXPERT_FINAL_RANKINGS))}%
                                                    </div>
                                                    <div className="text-sm text-blue-700">
                                                        Match with Expert Rankings
                                                    </div>
                                                </div>
                                                {showingExpertReview && (
                                                    <div className="text-sm text-blue-600">
                                                        Time to review: {reviewTimeRemaining}s
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="space-y-2 overflow-y-auto flex-1">
                                        {isComplete ? (
                                            FINAL_RANKINGS.map((item, index) => (
                                                <div 
                                                    key={index}
                                                    className="flex items-center p-2.5 text-sm bg-blue-50 rounded-lg border border-blue-100"
                                                >
                                                    <span className="w-6 h-6 flex items-center justify-center bg-blue-500 text-white rounded-full text-xs mr-2">
                                                        {index + 1}
                                                    </span>
                                                    <span className="text-gray-800">{item}</span>
                                                    <span className="ml-auto text-xs text-gray-500">
                                                        Expert: {EXPERT_FINAL_RANKINGS.indexOf(item) + 1}
                                                    </span>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-gray-500 text-center py-4">
                                                Rankings will be displayed here when the discussion is complete
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ScreenSizeCheck>
    );
};

export default App;